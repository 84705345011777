import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["part"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_ProductDetailsNavigation = _resolveComponent("ProductDetailsNavigation")!
  const _component_ProductDetailsInteractiveGuides = _resolveComponent("ProductDetailsInteractiveGuides")!
  const _component_ProductDetailsAttributeGroups = _resolveComponent("ProductDetailsAttributeGroups")!
  const _component_ProductDetailsRelationships = _resolveComponent("ProductDetailsRelationships")!
  const _component_Tab = _resolveComponent("Tab")!
  const _component_ProductDetailsClassifications = _resolveComponent("ProductDetailsClassifications")!
  const _component_Message = _resolveComponent("Message")!
  const _component_ProductDetailsRelatedLinks = _resolveComponent("ProductDetailsRelatedLinks")!
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_NavigationBtns = _resolveComponent("NavigationBtns")!
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_Btn = _resolveComponent("Btn")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.itemLoaded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Tabs, {
            modelValue: _ctx.currentTabIndex,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTabIndex) = $event)),
            class: "mt-4",
            desktop: _ctx.widerThan('md')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Tab, {
                title: _ctx.t('DetailsTabTitle'),
                icon: _ctx.componentOptions?.showTabIcons ? 'document' : undefined,
                extclass: "ext-tab-details"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ProductDetailsNavigation),
                  _createVNode(_component_ProductDetailsInteractiveGuides),
                  _createVNode(_component_ProductDetailsAttributeGroups),
                  _createVNode(_component_ProductDetailsRelationships)
                ]),
                _: 1
              }, 8, ["title", "icon"]),
              (_ctx.classifications)
                ? (_openBlock(), _createBlock(_component_Tab, {
                    key: 0,
                    title: _ctx.t('CategoriesTabTitle'),
                    icon: _ctx.componentOptions?.showTabIcons ? 'tree-view' : undefined,
                    extclass: "ext-tab-categories",
                    count: _ctx.classificationCount
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ProductDetailsClassifications)
                    ]),
                    _: 1
                  }, 8, ["title", "icon", "count"]))
                : _createCommentVNode("", true),
              (_ctx.downloads)
                ? (_openBlock(), _createBlock(_component_Tab, {
                    key: 1,
                    icon: _ctx.componentOptions?.showTabIcons ? 'download' : undefined,
                    title: _ctx.t('DownloadsTabTitle'),
                    loading: _ctx.downloadsLoading,
                    count: _ctx.downloadsCount,
                    extclass: "ext-tab-downloads"
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "detail-downloads", {}, () => [
                        _createVNode(_component_Message, { class: "p-2" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('DownloadsNotAvailable')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 3
                  }, 8, ["icon", "title", "loading", "count"]))
                : _createCommentVNode("", true),
              (_ctx.relatedLinks)
                ? (_openBlock(), _createBlock(_component_Tab, {
                    key: 2,
                    title: _ctx.t('LinksTabTitle'),
                    icon: _ctx.componentOptions?.showTabIcons ? 'link' : undefined,
                    extclass: "ext-tab-related-links",
                    count: _ctx.relatedLinkCount
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ProductDetailsRelatedLinks)
                    ]),
                    _: 1
                  }, 8, ["title", "icon", "count"]))
                : _createCommentVNode("", true)
            ]),
            _: 3
          }, 8, ["modelValue", "desktop"]),
          (_ctx.componentOptions?.hideBottomNavigation !== true)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["d-flex d-grid gap-3 justify-content-end align-items-center mt-4 pb-2", {
          'position-sticky bottom-0 bg-body': _ctx.componentOptions?.bottomNavigationSticky === true,
        }]),
                style: {"z-index":"5"}
              }, [
                _createVNode(_component_NavigationBtns, {
                  instance: _ctx.instance,
                  t: _ctx.t
                }, null, 8, ["instance", "t"]),
                _createVNode(_component_Btn, {
                  class: "ext-scroll-top",
                  onClick: _withModifiers(_ctx.scrollTop, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Arrow, {
                      dir: "up",
                      class: "me-2"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('BackToTop')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ], 2))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}