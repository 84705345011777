import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "h5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_Btn = _resolveComponent("Btn")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.t('NoResults')), 1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.t('Suggestions')) + ":", 1)
            ]),
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, _toDisplayString(_ctx.t('CheckSpelling')), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.t('DifferentKeywords')), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.t('ContactSupport')), 1)
            ]),
            _createVNode(_component_Btn, {
              onClick: _withModifiers(_ctx.clearResults, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Clear')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}