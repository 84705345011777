import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "nav nav-tabs" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component position-relative", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allowedModes, (mode) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "nav-item",
                  key: mode
                }, [
                  _createElementVNode("a", {
                    class: _normalizeClass(["nav-link", _ctx.routeView === _ctx.routeMap[mode] ? 'active' : '']),
                    href: "#",
                    onClick: _withModifiers(($event: any) => (_ctx.switchToView(mode)), ["prevent"])
                  }, _toDisplayString(_ctx.t(_ctx.translationMap[mode])), 11, _hoisted_4)
                ]))
              }), 128))
            ])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}