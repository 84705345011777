import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["value", "list", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Clickable = _resolveComponent("Clickable")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    open: _ctx.optionsOpen,
    "onUpdate:open": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.optionsOpen) = $event)),
    width: "100%",
    items: _ctx.optionsFiltered
  }, _createSlots({
    item: _withCtx(({ item }) => [
      _createVNode(_component_Clickable, {
        class: "dropdown-item d-flex",
        onClick: ($event: any) => (_ctx.onItemClick(item))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item[_ctx.textField]) + " ", 1),
          _createVNode(_component_Badge, { class: "ms-auto" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.hitCount), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    activator: _withCtx(({ on }) => [
      _createElementVNode("input", _mergeProps(_ctx.$attrs, _toHandlers(on), {
        class: "form-control",
        value: _ctx.inputText,
        list: _ctx.name,
        placeholder: _ctx.placeholder,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.optionsOpen = true))
      }), null, 16, _hoisted_1)
    ]),
    _: 2
  }, [
    (_ctx.noResultsMessage && !_ctx.optionsFiltered.length)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createVNode(_component_Message, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.noResultsMessage), 1)
              ]),
              _: 1
            })
          ])
        }
      : undefined
  ]), 1032, ["open", "items"]))
}