import {
  AttributeMergeMethodEnum,
  FilterSettings,
  PartTypeEnum,
  SearchTypeEnum,
  SortDirectionEnum,
  SortFieldEnum,
} from '@/common/services/swagger/index.defs';

export type ViewType = 'search' | 'detail' | 'compare';

export enum DisplayMode {
  table = 'table',
  grid = 'grid',
  list = 'list',
}

export interface IPagination {
  /**
   *  Current page (starting from 1)
   */
  page?: number;

  /**
   *  Items per page
   */
  pageSize?: number;
}

export interface ICompareRouteData {
  /**
   *  Products IDs to compare
   */
  productIds: string[];

  /**
   *  Classification filter applied to productIds
   */
  cids?: string[];

  /**
   *  When true, only attribute rows that are not the same across all productIds are visible
   */
  differencesOnly?: boolean;

  /**
   *  Method of applying attribute grouping.
   *  Merge - all product detail templates are merged into one template.
   *  FirstFound - first product detail template is used.
   *  Flat - attribute grouping is not applied.
   */
  mergeMethod?: AttributeMergeMethodEnum;
}

export interface IRouteData {
  /**
   *  Sets current view. Controls component visibility.
   */
  view?: ViewType;

  /**
   *  Search phrase - applied to 'search' view
   */
  searchText?: string;

  /**
   *  Search method/type - applied to 'search' view
   */
  searchType?: SearchTypeEnum;

  /**
   *  Category filter - applied to 'search' view
   */
  cid?: string;

  /**
   *  Parametric filters - applied to 'search' view
   */
  filters?: FilterSettings[];

  /**
   *  Pagination settings - applied to 'search' view
   */
  pagination?: IPagination;

  /**
   *  Field used for sorting - applied to 'search' view
   */
  sortField?: SortFieldEnum;

  /**
   *  Sort direction - applied to 'search' view
   */
  sortDirection?: SortDirectionEnum;

  /**
   *  Selected product ID - applied to 'detail' view
   */
  productId?: string;

  /**
   *  Sets current list mode (table, list, grid) - applied to 'search' view
   */
  displayMode?: DisplayMode;

  /**
   *  Part type filter - applied to 'search' view in Parts view
   */
  partsType?: PartTypeEnum;

  /**
   *  Spare part master filter (product to which displayed parts belong) - applied to 'search' view in Parts view
   */
  masterProductId?: string;

  /**
   *  Compare settings - applied to 'compare' view
   */
  compare?: ICompareRouteData;
}
