
import { defineComponent, ref } from 'vue';
import { DataType } from '@/products/api/configuration/application/DataType';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Btn from '@/common/components/Btn.vue';
import { SearchCommand } from '@/products/api/runtime/CommandExecutor';
import { SearchTypeEnum } from '@/common/services/swagger/index.defs';

export default defineComponent({
  name: ComponentName.searchNoResults,

  components: {
    InjectStyles,
    Btn,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, isWebComponent, instance, store, routeData, t } =
      setupComponent(root, props.instanceId, [DataType.Products]);

    const clearResults = async () => {
      try {
        await instance.value?.execute(
          new SearchCommand({
            searchText: '',
            searchType: routeData.value?.searchType as SearchTypeEnum,
          })
        );
      } catch (error) {
        // Ignore Error
      }
    };

    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      t,
      clearResults,
    };
  },
});
