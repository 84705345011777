
import { defineComponent, onMounted, ref, watch } from 'vue';
import Tooltip from 'bootstrap/js/dist/tooltip';

export default defineComponent({
  name: 'Tooltip',

  props: {
    text: String,
    placement: String,
    trigger: String,
  },

  setup(props) {
    const tooltip = ref<HTMLElement | null>(null);
    const container = ref(null);
    watch(
      () => props.text,
      () => {
        if (!tooltip.value) {
          return;
        }
        const bsTooltipInstance = Tooltip.getInstance(tooltip.value);
        if (bsTooltipInstance) {
          tooltip.value?.setAttribute('data-bs-original-title', props.text || '');
          bsTooltipInstance.setContent();
          bsTooltipInstance.update();
        }
      },
    );
    onMounted(() => {
      new Tooltip(tooltip.value, {
        container: container.value,
        placement: props.placement || 'top',
        trigger: props.trigger || 'hover click',
        title: props.text,
      });
    });

    return { tooltip, container };
  },
});
