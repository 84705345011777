import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 2,
  class: "px-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_Message = _resolveComponent("Message")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (_ctx.classifications)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.classifications, (group, groupName) => {
                    return (_openBlock(), _createElementBlock("li", { key: groupName }, [
                      _createElementVNode("strong", null, _toDisplayString(groupName), 1),
                      _createElementVNode("ul", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, (classification, index) => {
                          return (_openBlock(), _createElementBlock("li", { key: index }, [
                            _createElementVNode("div", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(classification, (item, nodeIndex) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  key: item.cid
                                }, [
                                  (item.inactive)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.name), 1))
                                    : (_openBlock(), _createElementBlock("a", {
                                        key: 1,
                                        href: "#",
                                        onClick: _withModifiers(($event: any) => (_ctx.setCategory(item)), ["prevent"])
                                      }, _toDisplayString(item.name), 9, _hoisted_5)),
                                  (nodeIndex + 1 < classification.length)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, "›"))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128))
                            ])
                          ]))
                        }), 128))
                      ])
                    ]))
                  }), 128))
                ])
              ]))
            : (_openBlock(), _createBlock(_component_Message, {
                key: 2,
                class: "p-2"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('ProductNotClassified')), 1)
                ]),
                _: 1
              }))
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}