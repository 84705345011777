import { defineCustomElement } from 'vue';
import PIS from '@/common/api/PIS';
import productsComponents from '@/products/components';
import catalogsComponents from '@/catalogs/components';
productsComponents.forEach((component) => {
  if (!customElements.get(component.name)) {
    const ctor = defineCustomElement(component);
    customElements.define(component.name, ctor);
  }
});

catalogsComponents.forEach((component) => {
  if (!customElements.get(component.name)) {
    const ctor = defineCustomElement(component);
    customElements.define(component.name, ctor);
  }
});

PIS.version = process.env.VUE_APP_VERSION;
PIS.build = process.env.VUE_APP_BUILD;

// eslint-disable-next-line
(window as any).PIS = PIS;
