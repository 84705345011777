import { vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["type", "placeholder"]
const _hoisted_2 = {
  key: 1,
  class: "invalid-feedback d-block"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["position-relative flex-grow-1 has-validation pis-form-input-clearable", [`pis-form-input-clearable-${_ctx.type}`]])
  }, [
    _withDirectives(_createElementVNode("input", _mergeProps({
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValue) = $event))
    }, _ctx.$attrs, {
      type: _ctx.type,
      class: ["form-control", [_ctx.lg && 'form-control-lg', _ctx.classInner, _ctx.isValid || 'is-invalid']],
      placeholder: _ctx.placeholder
    }), null, 16, _hoisted_1), [
      [_vModelDynamic, _ctx.computedValue]
    ]),
    (_ctx.innerValue?.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["clearing-container position-absolute top-50 end-0 translate-middle-y", [_ctx.isValid || 'is-invalid', _ctx.type === 'number' ? (_ctx.isValid ? 'me-4' : 'me-5') : 'me-1']]),
          style: _normalizeStyle([_ctx.type === 'number' && !_ctx.isValid ? 'padding-bottom:1.4em' : ''])
        }, [
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            style: {"background-size":"0.7em"},
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args)), ["prevent"]))
          })
        ], 6))
      : _createCommentVNode("", true),
    (!_ctx.isValid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.validationErrors.required)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString('Field is required')))
            : (_ctx.validationErrors.email)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString('Field must be an email')))
              : (_ctx.validationErrors.csemails)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString('Field must be a comma separated emails')))
                : (_ctx.validationErrors.minLength)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(`Field must be at least ${_ctx.validators.minLength.minLength} characters long`), 1))
                  : (_ctx.validationErrors.min)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(`Field value must not be lower than ${_ctx.validators.min.min}`), 1))
                    : (_ctx.validationErrors.max)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(`Field value must not be greater than ${_ctx.validators.max.max}`), 1))
                      : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}