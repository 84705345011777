
import { PropType, computed, defineComponent, onMounted, ref, watch } from 'vue';
import { ValidationErrors, validate } from '@/common/helpers/validation';

export default defineComponent({
  props: {
    modelValue: String,
    placeholder: String,
    classInner: String,
    lg: Boolean,
    validators: { type: Object, default: null },
    type: {
      type: String as PropType<'text' | 'number'>,
      required: false,
      default: 'text',
    },
  },

  emits: ['update:modelValue', 'update:validation', 'change', 'clear'],

  setup(props, { emit }) {
    // Use separate innerValue to allow of using this component without attaching v-model
    const innerValue = ref<string | undefined>('');
    const isDirty = ref(false);
    const isValid = ref(true);
    const validationErrors = ref<ValidationErrors>({});

    const computedValue = computed({
      get: () => innerValue.value,
      set: (val: unknown) => {
        const value = `${val}`;
        innerValue.value = value;
        isDirty.value = true;
        emit('update:modelValue', value);
      },
    });

    const setInnerValue = (value: string | undefined) => {
      // Add delay to prevent modals to be closed if the website is using bootstrap JS.
      setTimeout(() => {
        innerValue.value = value;
      }, 10);
    };

    const clear = () => {
      setInnerValue('');
      emit('clear');
      emit('update:modelValue', '');
    };
    const syncInnerValue = () => setInnerValue(props.modelValue);

    // Sync innerValue with modelValue
    watch(
      () => props.modelValue,
      () => syncInnerValue(),
    );

    watch(
      () => innerValue.value,
      (currentValue: any) => {
        const errors: ValidationErrors = {};
        if (isDirty.value && props.validators) {
          Object.assign(errors, validate(currentValue, props.validators));
        }
        validationErrors.value = errors;
        isValid.value = !Object.keys(errors).length;
        emit('update:validation', validationErrors.value);
      },
    );

    onMounted(() => syncInnerValue());

    return {
      isValid,
      innerValue,
      computedValue,
      validationErrors,
      clear,
    };
  },
});
