export enum ComponentName {
  common = 'common',

  searchCategories = 'pis-products-search-categories',
  searchFilters = 'pis-products-search-filters',
  searchFiltersStatus = 'pis-products-search-filters-status',
  searchForm = 'pis-products-search-form',
  searchGrid = 'pis-products-search-grid',
  searchHeader = 'pis-products-search-header',
  searchList = 'pis-products-search-list',
  searchNoResults = 'pis-products-search-no-results',
  searchNoResultsView = 'pis-products-search-no-results-view',
  searchPagination = 'pis-products-search-pagination',
  searchTable = 'pis-products-search-table',
  searchToolbar = 'pis-products-search-toolbar',
  searchView = 'pis-products-search-view',
  searchPartTypes = 'pis-products-search-part-types',
  searchNarrowByProduct = 'pis-products-search-narrow-by-product',
  searchFavoriteCategories = 'pis-products-search-favorite-categories',
  searchFavoriteViews = 'pis-products-search-favorite-views',
  searchSelection = 'pis-products-search-selection',

  detailsHeader = 'pis-products-details-header',
  detailsData = 'pis-products-details-data',
  detailsGallery = 'pis-products-details-gallery',
  detailsView = 'pis-products-details-view',
  detailsGeneralInformation = 'pis-products-details-general-information',
  detailsReplacements = 'pis-products-details-replacements',
  detailsRelatedLinks = 'pis-products-details-related-links',
  detailsIdentifiers = 'pis-products-details-identifiers',
  detailsAttributeGroups = 'pis-products-details-attribute-groups',
  detailsInteractiveGuides = 'pis-products-details-interactive-guides',
  detailsRelationships = 'pis-products-details-relationships',
  detailsNavigation = 'pis-products-details-navigation',
  detailsClassifications = 'pis-products-details-classifications',
  detailsActions = 'pis-products-details-actions',

  breadcrumbs = 'pis-products-breadcrumbs',
  instance = 'pis-products-instance',
  layout = 'pis-products-layout',
  loadingIndicator = 'pis-products-loading-indicator',
  preview = 'pis-products-preview',
  mobileNav = 'pis-products-mobile-nav',

  tableView = 'pis-products-table-view',
  listView = 'pis-products-list-view',
  gridView = 'pis-products-grid-view',

  notifications = 'pis-products-notifications',

  compare = 'pis-products-compare',
  compareView = 'pis-products-compare-view',

  actionModals = 'pis-products-action-modals',
}
