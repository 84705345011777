import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-check mt-3"
}
const _hoisted_2 = { class: "form-check-label" }
const _hoisted_3 = ["value", "disabled"]
const _hoisted_4 = { class: "form-label fw-bolder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.catalogOptions, (catalogOption) => {
    return (_openBlock(), _createElementBlock("div", {
      key: catalogOption.type
    }, [
      (
        [_ctx.catalogType.Company, _ctx.catalogType.Personal].includes(
          catalogOption.type || _ctx.catalogType.CustomerGroup,
        )
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("label", _hoisted_2, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ownerType) = $event)),
                name: "catalogType",
                type: "radio",
                class: "form-check-input",
                value: catalogOption.type,
                disabled: !_ctx.editable
              }, null, 8, _hoisted_3), [
                [_vModelRadio, _ctx.ownerType]
              ]),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t(`${catalogOption.type}Catalog`)), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]))
  }), 128))
}