
import { Instance } from '@/catalogs/api/Instance';
import PIS from '@/common/api/PIS';
import Btn from '@/common/components/Btn.vue';
import Modal from '@/common/components/Modal.vue';
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import {
  CopyProductCommand,
  GetAllCatalogsCommand,
  CopyProductCommandArgs,
  AddNotificationCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import { TranslateFunc } from '@/catalogs/composables/setupComponent';
import {
  Catalog,
  CatalogDetail,
  CatalogItem,
  CatalogTypeEnum,
  QuantityItem,
} from '@/common/services/swagger/index.defs';
import { NotificationType } from '@/common/api/runtime/INotification';
import { CatalogProductCopyActionData } from '@/catalogs/api/runtime/CatalogActionData';

export default defineComponent({
  components: {
    Btn,
    Modal,
  },
  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: String,
    data: { type: Object as PropType<CatalogProductCopyActionData>, required: true },
  },
  emits: ['update:show'],
  setup(props, { emit }) {
    const catalogType: typeof CatalogTypeEnum = CatalogTypeEnum;
    const show = ref(false);
    const instance = PIS.Catalogs.getInstance(props.instanceId) as Instance;
    const copyTo = ref<string | null>(null);
    const allCatalogs = ref<CatalogItem[]>([]);
    const isFormValid = computed<boolean>(() => copyTo.value !== null);
    const productCatalog = computed<CatalogDetail | undefined>(
      () => (props.data.product.catalogs ?? {})[0] || undefined,
    );
    const copyToOptions = computed<string[]>(() =>
      allCatalogs.value.reduce((result: string[], current: CatalogItem) => {
        if (current.code != productCatalog.value?.code && current.editable) {
          return result.concat([current.code]);
        }
        return result;
      }, []),
    );
    const close = () => emit('update:show', false);
    const onSave = async () => {
      const catalogItem: Catalog = props.data.catalog;
      const catalogDetail: CatalogDetail | undefined = props.data.product.catalogs?.find(
        (catalog: CatalogDetail) => catalog.code === catalogItem.code,
      );
      if (!catalogDetail) {
        return;
      }
      const quantity: QuantityItem | undefined = (catalogDetail?.quantities ?? {})[0];
      if (!quantity) {
        return;
      }
      const copyCommandArgs: CopyProductCommandArgs = {
        onSuccessMessage: props.t('CatalogProductCopySuccess'),
        sourceQuantityIds: [quantity.id],
        sourceCatalogId: catalogDetail?.id,
        targetCatalogId: '',
      };
      try {
        if (!copyTo.value) {
          return;
        }
        const targetCatalog: CatalogItem | undefined = allCatalogs.value.find(
          (catalogItem: CatalogItem) => catalogItem.code === copyTo.value,
        );
        const targetCatalogId = targetCatalog?.id;
        if (!targetCatalogId) {
          return;
        }
        copyCommandArgs.targetCatalogId = targetCatalogId;
        await instance.execute(new CopyProductCommand(copyCommandArgs));
      } catch (error) {
        // Ignore error
      }
      close();
    };

    const onAllCatalogsLoad = async () => {
      try {
        allCatalogs.value = (await instance.execute(new GetAllCatalogsCommand())) as CatalogItem[];
      } catch (error) {
        // Ignore error
      }
    };

    onMounted(async () => {
      try {
        onAllCatalogsLoad();
        show.value = true;
      } catch (error) {
        await instance.execute(
          new AddNotificationCommand({ type: NotificationType.danger, message: error.message }),
        );
      }
    });

    return {
      show,
      copyTo,
      isFormValid,
      catalogType,
      copyToOptions,
      close,
      onSave,
    };
  },
});
