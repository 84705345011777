
import { computed, defineComponent, ref, watch } from 'vue';
import InjectStyles from '@/common/components/InjectStyles.vue';
import { setupComponent } from '@/catalogs/composables/setupComponent';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { routeMap } from '@/common/helpers/displayModeToRoute';
import {
  CatalogProductSwitchDisplayModeOption,
  ICatalogProductSwitchOptions,
} from '@/catalogs/api/configuration/components/ICatalogProductSwitchOptions';
import {
  AliasesCommand,
  CatalogsCommand,
  Command,
  ProductsCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import { ViewType } from '@/catalogs/api/runtime/IRouteData';

type NewableCommand = { new (): Command<unknown, unknown> };

export default defineComponent({
  name: ComponentName.productSwitch,
  components: {
    InjectStyles,
  },
  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const { componentName, instance, isReady, isWebComponent, store, t } = setupComponent(
      root,
      props.instanceId,
    );
    const componentOptions = computed<ICatalogProductSwitchOptions | undefined>(
      () =>
        store.value?.options.components?.catalogProductSwitch ??
        ({} as ICatalogProductSwitchOptions),
    );
    const validModes: string[] = Object.values(CatalogProductSwitchDisplayModeOption);
    const isValidMode = (mode: string): boolean => validModes.includes(mode);
    const allowedModes = computed(() => {
      if (componentOptions.value) {
        return (componentOptions.value.displayModes || []).filter(isValidMode);
      }
      return [];
    });
    const routeView = computed<ViewType | undefined>(() => instance.value?.router.routeData.view);
    const translationMap: Record<CatalogProductSwitchDisplayModeOption, string> = {
      [CatalogProductSwitchDisplayModeOption.catalog]: 'Catalogs',
      [CatalogProductSwitchDisplayModeOption.product]: 'Products',
      [CatalogProductSwitchDisplayModeOption.alias]: 'Aliases',
    };
    const viewCommandMap: Record<CatalogProductSwitchDisplayModeOption, NewableCommand> = {
      [CatalogProductSwitchDisplayModeOption.catalog]: CatalogsCommand,
      [CatalogProductSwitchDisplayModeOption.product]: ProductsCommand,
      [CatalogProductSwitchDisplayModeOption.alias]: AliasesCommand,
    };
    const switchToView = async (mode: CatalogProductSwitchDisplayModeOption) => {
      if (!isValidMode(mode)) {
        return;
      }
      try {
        if (viewCommandMap[mode]) {
          await instance.value?.execute(new viewCommandMap[mode]());
        }
      } catch (error) {
        // Ignore error
      }
    };

    watch(
      // Watch both - instance init & display mode, as it could have changed
      () => `${instance.value?.isInitialized()}${componentOptions.value?.defaultDisplayMode}`,
      async () => {
        if (!instance.value?.isInitialized()) {
          return;
        }
        const displayMode: CatalogProductSwitchDisplayModeOption | undefined =
          componentOptions.value?.defaultDisplayMode;
        const currentView: CatalogProductSwitchDisplayModeOption | undefined = routeView.value
          ? routeMap[routeView.value]
          : undefined;
        if (displayMode && currentView !== displayMode) {
          await switchToView(displayMode);
        }
      },
    );

    return {
      root,
      store,
      isReady,
      routeMap,
      routeView,
      allowedModes,
      componentName,
      isWebComponent,
      translationMap,
      CatalogProductSwitchDisplayModeOption,
      t,
      switchToView,
    };
  },
});
