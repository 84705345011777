import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "pis-heading ext-attr-group-heading" }
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_AttributesTable = _resolveComponent("AttributesTable")!
  const _component_PrmList = _resolveComponent("PrmList")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prmDataFiltered, (data, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "mt-4 ext-attr-group"
            }, [
              _createElementVNode("div", {
                id: `pis-anchor-agm-${data.attributeGroup.code}`,
                class: "ext-attr-group-inner"
              }, [
                _createElementVNode("h4", _hoisted_4, _toDisplayString(data.attributeGroup.description), 1),
                (data.attributeGroup.attributes)
                  ? (_openBlock(), _createBlock(_component_AttributesTable, {
                      key: 0,
                      attributes: data.attributeGroup.attributes,
                      instance: _ctx.instance,
                      "hide-document-links": _ctx.hideDocumentLinks,
                      t: _ctx.t
                    }, null, 8, ["attributes", "instance", "hide-document-links", "t"]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.prms, (prm) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: prm.code,
                    class: "ext-attr-group-list"
                  }, [
                    (prm && _ctx.productId)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "ps-lg-3",
                          id: `pis-anchor-prm-${prm.code}`
                        }, [
                          _createElementVNode("h5", _hoisted_6, _toDisplayString(prm.description), 1),
                          _createVNode(_component_PrmList, {
                            relationship: prm,
                            instance: _ctx.instance,
                            productId: _ctx.productId,
                            t: _ctx.t,
                            emit: _ctx.emit
                          }, null, 8, ["relationship", "instance", "productId", "t", "emit"])
                        ], 8, _hoisted_5))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ], 8, _hoisted_3)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}