import { IHttpService } from '@/common/api/runtime/IHttpService';
import { InstanceType } from '@/common/api/runtime/IInstance';
import { ClassificationsService } from '@/common/services/swagger/ClassificationsService';
import { FavoriteViewsService } from '@/common/services/swagger/FavoriteViewsService';
import { FeedbackService } from '@/common/services/swagger/FeedbackService';
import { ImagesService } from '@/common/services/swagger/ImagesService';
import {
  AppSettings,
  AutocompleteSearchResult,
  FilterSettings,
  FilterValueSearchResult,
  ImageSearchResult,
  PartTypeEnum,
  PrmSearchResult,
  SearchDataTypeEnum,
  SearchTypeEnum,
  serviceOptions,
  SortDirectionEnum,
  SortFieldEnum,
  TranslationItem,
  TreeTypeEnum,
  ClassificationNodesSearchResult,
  FavoriteView,
  FeedbackSettings,
  PrintResult,
  AttributeMergeMethodEnum,
  CountSearchResult,
  PrmFilterSettings,
  CatalogOptionsResult,
  CatalogSearchResult,
  CatalogActionResult,
  CatalogProductAddItem,
  CatalogTypeEnum,
  PrmSearchSettings,
  PagerSettings,
} from '@/common/services/swagger/index.defs';
import { ProductsService } from '@/common/services/swagger/ProductsService';
import { TranslationsService } from '@/common/services/swagger/TranslationsService';
import axios from 'axios';
import { reactive } from 'vue';
import { DataType } from '../configuration/application/DataType';
import { Instance } from '../Instance';
import { IErrorEvent, ILoadEvent } from './ILoadEvent';
import { IRouteData } from './IRouteData';
import { StoreService } from './StoreService';
import { NotificationType } from '@/common/api/runtime/INotification';
import { CatalogsService } from '@/common/services/swagger/CatalogsService';
import { CatalogsProductsService } from '@/common/services/swagger/CatalogsProductsService';

export const SESSION_TRANSLATIONS_KEY = 'pis-products-translations';

export class HttpService implements IHttpService {
  instanceType: InstanceType = 'Products';
  private storeService: StoreService;
  private axiosInstance;
  constructor(private instance: Instance, baseURL?: string, accessToken?: string) {
    this.axiosInstance = axios.create({
      baseURL,
      timeout: 60000,
    });

    this.axiosInstance.interceptors.request.use(
      (config) => {
        if (config && config.headers) {
          const currentAccessToken = instance.store?.options?.accessToken;
          config.headers['Authorization'] = `Bearer ${currentAccessToken || accessToken}`;

          if (window['PIS'] && window['PIS']['version']) {
            config.headers['Component-Version'] = window['PIS']['version'];
          }

          if (instance.props['dashboard'] === true) {
            config.headers['dashboard'] = 'true';
          }
        }
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      },
    );

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.message == 'canceled') {
          return Promise.reject(error);
        }

        const errorData = error?.response?.data;

        const errorEvent: IErrorEvent = {
          type: 'ServerError',
          message: error.message,
          isHandled: false,
        };

        if (errorData) {
          errorEvent.message = errorData.Message;
          errorEvent.type = errorData.Type;
          errorEvent.traceId = errorData.TraceId;
        }

        if (instance.store.options.errorInterceptor) {
          const result = instance.store.options.errorInterceptor(errorEvent);
          if (!result.isHandled) {
            instance.store.notifications.push({
              type: NotificationType.danger,
              message: result.message || 'Unknown error',
            });
          }
        } else {
          instance.store.notifications.push({
            type: NotificationType.danger,
            message: errorEvent.message || 'Unknown error',
          });
        }

        return Promise.reject(error);
      },
    );

    serviceOptions.axios = this.axiosInstance;

    this.storeService = new StoreService(instance);
  }

  private setAxiosInstance() {
    serviceOptions.axios = this.axiosInstance;
  }

  public async load(newRoute?: IRouteData): Promise<void> {
    this.setAxiosInstance();
    if (!this.instance.router) return;
    try {
      const route = newRoute || this.instance.router.routeData;
      switch (route.view) {
        case 'search':
          return await this.query(route);
        case 'detail':
          return await this.detail(route);
        case 'compare':
          return await this.compare(route);
        default:
          throw new Error(`Unknown view type "${route.view}" to be handled by HttpService`);
      }
    } catch (error) {
      this.instance.store.data.isLoadingData = false;
      // eslint-disable-next-line no-console
      console.error('Load failed', error);
    }
  }

  public async paginate(routeData: IRouteData): Promise<void> {
    this.setAxiosInstance();
    this.instance.store.data.isLoadingData = true;

    const result = await ProductsService.search({
      body: {
        attributeCodes: this.instance.store.options.components?.search?.attributeCodes,
        appSettings: this.instance.store?.options.application as AppSettings,
        dataTypes: [SearchDataTypeEnum.Products],
        pager: {
          page: routeData?.pagination?.page ?? 1,
          pageSize: routeData?.pagination?.pageSize ?? 12,
        },
        search: {
          cid: routeData.cid,
          partType: (routeData.partsType as PartTypeEnum) || PartTypeEnum.All,
          searchType: routeData.searchType as SearchTypeEnum,
          searchText: routeData.searchText ?? undefined,
          filterSettings: routeData.filters,
          masterProductId: routeData.masterProductId ?? undefined,
        },
        sort: {
          field: routeData.sortField ?? SortFieldEnum.Product,
          direction: routeData.sortDirection ?? SortDirectionEnum.Ascending,
        },
      },
    });

    this.instance.eventBus.emit('search-loaded', result);

    if (result.products) {
      this.storeService.setData(DataType.Products, result.products);
    }

    this.instance.store.data.isLoadingData = false;
  }

  public async query({
    pagination,
    searchText,
    searchType,
    cid,
    filters,
    sortField,
    sortDirection,
    partsType,
    masterProductId,
  }: IRouteData): Promise<void> {
    this.setAxiosInstance();
    const dataTypes = this.instance.getRegisteredSearchDataTypes();

    if (!dataTypes.length) {
      return Promise.resolve();
    }

    this.instance.store.data.isLoadingData = true;

    const result = await ProductsService.search({
      body: {
        attributeCodes: this.instance.store.options.components?.search?.attributeCodes,
        appSettings: this.instance.store?.options.application as AppSettings,
        dataTypes: dataTypes,
        pager: {
          page: pagination?.page ?? 1,
          pageSize: pagination?.pageSize ?? 12,
        },
        search: {
          cid,
          partType: (partsType as PartTypeEnum) || PartTypeEnum.All,
          searchType: searchType as SearchTypeEnum,
          searchText: searchText ?? undefined,
          filterSettings: filters,
          masterProductId: masterProductId ?? undefined,
        },
        sort: {
          field: sortField ?? SortFieldEnum.Product,
          direction: sortDirection ?? SortDirectionEnum.Ascending,
        },
      },
    });

    this.instance.eventBus.emit('search-loaded', result);

    if (result.products) {
      this.storeService.setData(DataType.Products, result.products);
    }
    if (result.classifications) {
      this.storeService.setData(DataType.Classifications, result.classifications);
    }
    if (result.filters) {
      this.storeService.setData(DataType.Filters, result.filters);
    }
    if (result.breadcrumbs) {
      this.storeService.setData(DataType.Breadcrumbs, result.breadcrumbs);
    }
    if (result.configurators) {
      this.storeService.setData(DataType.Configurators, result.configurators);
    }

    if (result.partTypes) {
      this.storeService.setData(DataType.PartTypes, result.partTypes);
    }

    if (result.masterProducts) {
      this.storeService.setData(DataType.MasterProducts, result.masterProducts);
    }

    this.instance.store.data.isLoadingData = false;
    this.instance.eventBus.emit('search-changed');
  }

  public async detail({ productId, cid }: IRouteData): Promise<void> {
    this.setAxiosInstance();
    if (!productId) {
      return Promise.resolve();
    }

    const dataTypes = this.instance.getRegisteredDetailsDataTypes();

    if (!dataTypes.length) {
      return Promise.resolve();
    }

    this.instance.store.data.isLoadingData = true;
    this.instance.store.data.productDetail = undefined;
    const result = await ProductsService.detail({
      body: {
        appSettings: {
          ...(this.instance.store?.options.application as AppSettings),
          productRelationshipFiltering: true,
        },
        dataTypes: dataTypes,
        search: { productId, cid },
        attributeCodes: this.instance.store?.options.components?.details?.attributeCodes,
      },
    });

    this.instance.eventBus.emit('detail-loaded', result);

    if (result.productDetails) {
      this.storeService.setData(DataType.ProductDetails, result.productDetails);
      this.storeService.setData(DataType.ProductIdToName, {
        item: result.productDetails.item,
        requestedProductId: productId,
      });
    } else {
      this.storeService.setData(DataType.ProductDetails, undefined);
    }

    if (result.attributeGroups) {
      this.storeService.setData(DataType.AttributeGroups, result.attributeGroups);
    }
    if (result.productRelationships) {
      this.storeService.setData(DataType.ProductRelationships, result.productRelationships);
    }
    if (result.productClassifications) {
      this.storeService.setData(DataType.ProductClassifications, result.productClassifications);
    }

    if (result.interactiveGuides) {
      this.storeService.setData(DataType.InteractiveGuides, result.interactiveGuides);
    }

    if (result.relatedLinks) {
      this.storeService.setData(DataType.RelatedLinks, result.relatedLinks);
    } else {
      this.storeService.setData(DataType.RelatedLinks, undefined);
    }

    this.storeService.setData(DataType.RelatedProducts, result.relatedProducts || undefined);

    this.instance.store.data.isLoadingData = false;
  }

  public async compare(routeData: IRouteData): Promise<void> {
    this.setAxiosInstance();
    this.instance.store.data.isLoadingData = true;
    const result = await ProductsService.compare({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        search: {
          productIds: routeData.compare?.productIds ?? [],
          mergeMethod: routeData.compare?.mergeMethod ?? AttributeMergeMethodEnum.Flat,
          cids: routeData.compare?.cids ?? [],
          differencesOnly: routeData.compare?.differencesOnly,
        },
      },
    });
    this.instance.eventBus.emit('compare-loaded', result);
    if (result) {
      this.storeService.setData(DataType.ProductCompare, result);
    }

    this.instance.store.data.isLoadingData = false;
  }

  public async preview(productId: string): Promise<void> {
    this.setAxiosInstance();
    this.instance.store.data.isLoadingData = true;
    const result = await ProductsService.preview({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        search: {
          productId,
          cid: this.instance.getRouteData().cid,
          filterSettings: this.instance.getRouteData().filters,
        },
      },
    });
    this.instance.eventBus.emit('preview-loaded', result);
    if (result) {
      this.storeService.setData(DataType.ProductPreview, result);
    }

    this.instance.store.data.isLoadingData = false;
  }

  public async filterValues(attributeCodes: string[]): Promise<FilterValueSearchResult> {
    this.setAxiosInstance();

    const { searchText, searchType, cid, filters, partsType } = this.instance.router.routeData;

    return await ProductsService.filters({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        search: {
          cid,
          searchText,
          searchType: searchType as SearchTypeEnum,
          attributeCodes,
          partType: partsType as PartTypeEnum,
          filterSettings: filters,
        },
      },
    });
  }

  public async loadImages(products: string[]): Promise<ImageSearchResult | undefined> {
    this.setAxiosInstance();
    try {
      return await ImagesService.getByProductIds({
        body: {
          appSettings: this.instance.store?.options.application as AppSettings,
          search: {
            products,
          },
        },
      });
    } catch (exception) {
      this.instance.logger.log('Failed to fetch images', exception);
    }
  }

  public async loadImagesById(imageIds: string[]): Promise<ImageSearchResult | undefined> {
    this.setAxiosInstance();
    try {
      return await ImagesService.getByImageIds({
        body: {
          appSettings: this.instance.store?.options.application as AppSettings,
          search: {
            imageIds,
          },
        },
      });
    } catch (exception) {
      this.instance.logger.log('Failed to fetch images', exception);
    }
  }

  public async loadTranslations(): Promise<TranslationItem[] | undefined> {
    this.setAxiosInstance();
    const useCache = this.instance.store.options.cacheTranslations;
    const lang = this.instance.store.options.application?.langCode ?? 'en';
    const key = SESSION_TRANSLATIONS_KEY + '-' + lang;

    let data: TranslationItem[] | undefined;

    if (useCache) {
      try {
        const json = window.sessionStorage.getItem(key);
        if (json) {
          data = JSON.parse(json);
        }
      } catch (error) {
        // ignore
      }
    }

    if (!data) {
      data = await TranslationsService.translations({
        body: {
          appSettings: this.instance.store?.options.application as AppSettings,
          components: [],
        },
      });

      if (useCache) {
        try {
          window.sessionStorage.setItem(key, JSON.stringify(data));
        } catch (error) {
          // ignore
        }
      }
    }

    const interceptFunc = this.instance.store?.options.dataInterceptor;

    const loadEvent: ILoadEvent = {
      type: DataType.Translations,
      data: reactive({ items: data }),
      instance: this.instance,
    };

    if (interceptFunc) {
      const inc = await interceptFunc(loadEvent);
      return inc.data.items;
    } else {
      return data;
    }
  }

  public async loadPrmRelationship(
    search: PrmSearchSettings,
    pager: PagerSettings,
  ): Promise<PrmSearchResult> {
    this.setAxiosInstance();
    return await ProductsService.relationships({
      body: {
        appSettings: {
          ...(this.instance.store?.options.application as AppSettings),
          productRelationshipFiltering: true,
        },
        pager,
        search,
      },
    });
  }

  public async loadPrmRelationshipFilters(
    search: PrmFilterSettings,
  ): Promise<FilterValueSearchResult> {
    this.setAxiosInstance();
    return await ProductsService.relationshipFilters({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        search,
      },
    });
  }

  public async autocomplete(searchText: string): Promise<AutocompleteSearchResult> {
    this.setAxiosInstance();
    const { cid, searchType, partsType, filters } = this.instance.router.routeData;

    const result = await ProductsService.autocomplete({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        pager: {
          page: 1,
          pageSize: 5,
        },
        search: {
          cid,
          searchType: searchType as SearchTypeEnum,
          searchText: searchText ?? undefined,
          filterSettings: filters,
          partType: partsType as PartTypeEnum,
        },
      },
    });

    return result;
  }

  private _lastFilterLoad = {
    key: '',
    count: 0,
  };

  public async loadFiltersResultCount(
    filterSettings: FilterSettings[],
  ): Promise<CountSearchResult> {
    this.setAxiosInstance();
    const { cid, searchType, searchText, partsType } = this.instance.router.routeData;
    const params = {
      appSettings: this.instance.store?.options.application as AppSettings,

      search: {
        cid,
        searchType: searchType as SearchTypeEnum,
        searchText,
        filterSettings,
        partType: partsType as PartTypeEnum,
      },
    };

    const paramsKey = JSON.stringify(params);

    if (this._lastFilterLoad.key == paramsKey) {
      return Promise.resolve({ totalCount: this._lastFilterLoad.count });
    }

    const result = await ProductsService.count({
      body: params,
    });

    this._lastFilterLoad.key = paramsKey;
    this._lastFilterLoad.count = result.totalCount;

    return result;
  }

  public async loadFavoriteViews(ids: string[]): Promise<FavoriteView[]> {
    this.setAxiosInstance();
    try {
      const result = await FavoriteViewsService.load({
        body: {
          appSettings: this.instance.store?.options.application as AppSettings,
          search: { ids },
        },
      });
      const items: FavoriteView[] = result.items ? result.items : [];
      this.storeService.setData(DataType.FavoriteViews, { items });
      return items;
    } catch (exception) {
      this.instance.logger.log('Failed to fetch favorite views', exception);
      return [];
    }
  }

  public async saveFavoriteView(
    id: string,
    code: string,
    routeData: IRouteData,
  ): Promise<FavoriteView | undefined> {
    this.setAxiosInstance();
    try {
      const result = await FavoriteViewsService.save({
        body: {
          appSettings: this.instance.store?.options.application as AppSettings,
          favoriteView: {
            id,
            code,
            data: {
              treeType: TreeTypeEnum.Products,
              pager: {
                page: routeData?.pagination?.page ?? 1,
                pageSize: routeData?.pagination?.pageSize ?? 12,
              },
              search: {
                cid: routeData.cid,
                partType: routeData.partsType as PartTypeEnum,
                searchType: routeData.searchType as SearchTypeEnum,
                searchText: routeData.searchText ?? undefined,
                filterSettings: routeData.filters,
                masterProductId: routeData.masterProductId ?? undefined,
              },
              sort: {
                field: routeData.sortField ?? SortFieldEnum.Product,
                direction: routeData.sortDirection ?? SortDirectionEnum.Ascending,
              },
            },
          },
        },
      });
      if (result.id) {
        const found: FavoriteView | undefined = (
          this.instance.store?.data.favoriteViews?.items || []
        ).find((item: FavoriteView) => item.id === result.id);
        if (found) {
          Object.assign(found, result);
        } else {
          this.storeService.setData(DataType.FavoriteViews, {
            items: [...(this.instance.store?.data.favoriteViews?.items || []), result],
          });
        }
        return result;
      }
    } catch (exception) {
      this.instance.logger.log('Failed to fetch favorite views', exception);
    }
  }

  public async deleteFavoriteView(id: string): Promise<string | undefined> {
    this.setAxiosInstance();
    try {
      const deletedSuccessfully = await FavoriteViewsService.delete({
        body: {
          appSettings: this.instance.store?.options.application as AppSettings,
          favoriteView: { id },
        },
      });
      if (deletedSuccessfully) {
        this.storeService.setData(DataType.FavoriteViews, {
          items: (this.instance.store?.data.favoriteViews?.items ?? []).filter(
            (item) => item.id !== id,
          ),
        });
        return id;
      }
    } catch (exception) {
      this.instance.logger.log('Failed to delete favorite view', exception);
    }
  }

  public async loadClassificationsNodes(cids: string[]): Promise<ClassificationNodesSearchResult> {
    this.setAxiosInstance();
    const result = await ClassificationsService.nodes({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        search: {
          cids,
        },
      },
    });

    return result;
  }

  public async generatePDFUrl(productId: string): Promise<string | undefined> {
    this.setAxiosInstance();
    this.instance.store.data.isLoadingData = true;
    try {
      const result: PrintResult = await ProductsService.generate({
        body: {
          search: { productId },
          appSettings: this.instance.store?.options.application as AppSettings,
        },
      });
      if (result && result.guid) {
        this.instance.store.data.isLoadingData = false;
        return `${this.instance.getOptions().baseUrl}/v1/Products/Pdf/Download?guid=${result.guid}`;
      }
    } catch (exception) {
      this.instance.logger.log('Failed to print product to pdf', exception);
    }
    this.instance.store.data.isLoadingData = false;
  }

  public async saveFeedback(feedback: FeedbackSettings): Promise<void> {
    this.setAxiosInstance();
    this.instance.store.data.isLoadingData = true;
    try {
      await FeedbackService.feedback({
        body: {
          feedback,
          appSettings: this.instance.store?.options.application as AppSettings,
        },
      });
    } catch (exception) {
      this.instance.logger.log('Failed to save feedback', exception);
    }
    this.instance.store.data.isLoadingData = false;
  }

  public async getCatalogOptions(): Promise<CatalogOptionsResult> {
    this.setAxiosInstance();
    return CatalogsService.options({
      body: {
        appSettings: {
          appCode: this.instance.store?.options.application?.appCode as string,
          langCode: this.instance.store?.options.application?.langCode,
        },
      },
    });
  }

  public async getAllCatalogs(): Promise<CatalogSearchResult> {
    this.setAxiosInstance();
    return CatalogsService.search({
      body: {
        appSettings: {
          appCode: this.instance.store?.options.application?.appCode as string,
          langCode: this.instance.store?.options.application?.langCode,
        },
        /** @todo Remove backend limit */
        pager: { page: 1, pageSize: 1000 },
      },
    });
  }

  public async addCatalog(
    code: string,
    type: CatalogTypeEnum,
    description: string,
  ): Promise<CatalogActionResult> {
    this.setAxiosInstance();
    return CatalogsService.add({
      body: {
        appSettings: {
          appCode: this.instance.store?.options.application?.appCode as string,
          langCode: this.instance.store?.options.application?.langCode,
        },
        code,
        type,
        description,
      },
    });
  }

  public async addProductToCatalog(
    catalogId: string,
    products: CatalogProductAddItem[],
  ): Promise<CatalogActionResult> {
    this.setAxiosInstance();
    return CatalogsProductsService.add({
      body: {
        appSettings: {
          appCode: this.instance.store?.options.application?.appCode as string,
          langCode: this.instance.store?.options.application?.langCode,
        },
        catalogId,
        products,
      },
    });
  }
}
